import React from "react"
import Layout from "../../components/layout"
import SponsorPage from "../../components/members/sponsors"

const SponsorsPage = () => {
    return (
        <Layout>
            <SponsorPage />
        </Layout>
    )
}

export default SponsorsPage
